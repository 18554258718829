a {
  color: #ba012b;
  text-decoration: none;
}

html {
  background-image: url(/black_white.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
}

body {
  background-color: rgba(255, 255, 255, 0.8) !important;
  width: 100vw;
  min-height: 100%;
  z-index: -1;
}

::-webkit-scrollbar {
  display: none;
}